import type Alpine from 'alpinejs';
import type * as Turbo from '@hotwired/turbo';
import { EventHandler } from './eventHandler';
import { ErrorMessage } from "@js/components/errorMessage";

/* eslint-disable @typescript-eslint/no-explicit-any */
type MissingAlpine = {
  bind: (key: string, callback: any) => void;
  stopObservingMutations: any;
  onlyDuringClone: any;
  interceptInit: any;
  findClosest: any;
  destroyTree: any;
  entangle: any;
  bound: any;
  $data: any;
  $nextTick: any;
  walk: any;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export type Alpinejs = (typeof Alpine & MissingAlpine);
export type TypeTurbo = typeof Turbo;

export const SEARCH_KEY = {
  ORDER: 'offer.order',
  FILTER_DATES: 'offer.filter.dates',
  FILTER_IS_ACCEPT: 'offer.filter.isAccept',
  FILTER_AREAS: 'offer.filter.areas',
  FILTER_MATCH_USER: 'offer.filter.matchUser',
  FILTER_JOB_CATEGORIES: 'offer.filter.jobCategories'
};

class AppContainer {
  private _alpine?: Alpinejs;
  private _turbo?: TypeTurbo;
  private _eventHandler = new EventHandler();
  private _errorMessages: Map<string, ErrorMessage> = new Map();
  private _isUseCamera = false;
  private _map: google.maps.Map | null = null;
  private _state = new Map();
  private _mode: 'intern' | 'spojob' = 'spojob' ;

  setApp(params: { turbo?: TypeTurbo, alpine?: Alpinejs, mode?: 'intern' | 'spojob' }) {
    if (params.turbo) {
      this._turbo = params.turbo;
      this.setConfirm(this._turbo);
    }

    if (params.alpine) {
      this._alpine = params.alpine;
    }

    if (params.mode) {
      this._mode = params.mode;
    }
  }

  // TODO: implement.
  setConfirm(turbo: TypeTurbo)  {
    const confirmMethod = (message: string, _element: HTMLFormElement, _submitter: HTMLElement | undefined) => {
      const confirmMethod: Promise<boolean> = new Promise(resolve => resolve(confirm(message)));

      return confirmMethod;
    }
    turbo.setConfirmMethod(confirmMethod);
  }

  get mode() {
    return this._mode;
  }

  get alpine(): Alpinejs {
    if (this._alpine) {
      return this._alpine;
    } else {
      throw Error('not found alpine instance.');
    }
  }

  set alpine(value: Alpinejs | undefined) {
    this._alpine = value;
  }

  get isUseCamera(): boolean {
    return this._isUseCamera;
  }

  set isUseCamera(value: boolean) {
    this._isUseCamera = value;
  }

  get map(): google.maps.Map {
    return this._map as google.maps.Map;
  }

  set map(value: google.maps.Map | null) {
    this._map = value;
  }

  get turbo(): TypeTurbo {
    if (this._turbo) {
      return this._turbo;
    } else {
      throw Error('not found Turbo instance.');
    }
  }

  get eventHandler() {
    return this._eventHandler;
  }

  get errorMessages() {
    return this._errorMessages;
  }

  setSessionData(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  getSessionData(key: string) {
    return this.storage.getItem(key);
  }

  setPermanentData(key: string, value: string) {
    this.permanentStorage.setItem(key, value);
  }

  getPermanentData(key: string) {
    return this.permanentStorage.getItem(key);
  }

  get storage() {
    return sessionStorage;
  }

  // TODO: use IndexedDB.
  get permanentStorage() {
    return localStorage;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setState(key: string, value: any) {
    this._state.set(key, value);
  }

  getState(key: string) {
    return this._state.get(key);
  }
}

export const appContainer = new AppContainer();

export type User = {
  id?: number;
  email?: string;
}
